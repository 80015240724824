import React, { useState, useContext, useEffect } from 'react';
import { Wrap, WrapInput, WrapList } from '../../components/Wrap';
import { Input,LabelInput, inputStyle,multSelect } from '../../components/Input';
import { SearchBox, IconeSearch, ItemsSearch } from "../../components/Search";
import { Button, CancelarBtn } from "../../components/Button";
import { Container, TitleStyle2,Items} from './styles'
import { HeaderList, BodyList } from '../../components/TabelaCadastro';
import { Modal, CircularProgress } from "@material-ui/core";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddIcon from '@material-ui/icons/Add';
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer } from '../../components/SharedComponents/table';
import { shade } from 'polished'
import { AiOutlineDelete, AiOutlineEdit,AiOutlineTeam,AiTwotoneDelete } from "react-icons/ai";
import { ThemeContext } from 'styled-components';
import { ERRORMESSAGE, SUCCESSMESSAGE, INFOMESSAGE } from '../../utils/types-messages';
import { useSnackbar } from 'notistack';
import { api } from '../../services/axios';
import { makeStyles } from '@material-ui/core/styles';
import { modalClose, ModalBox, StyledBox } from '../../components/Modal';
import CancelIcon from '@material-ui/icons/Cancel';
import MessageAlert from '../../components/MessageAlert';
import { useShowModal } from '../../contexts/SharedContext'
import { MySelect } from "../../components/InputSelect";
import InputMask from 'react-input-mask';
import Header from "../../components/Header"
import { MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
import { tint } from "polished";

export default function CadastroPlanoAula(props){
    const { enqueueSnackbar } = useSnackbar();
    const { colors, textTableColor } = useContext(ThemeContext);
    const [planosAula, setPlanosAula] = useState([]);
    const [anoConsulta, setAnoConsulta] = useState('');
    const [estudoConsulta, setEstudoConsulta] = useState('');
    const [loadDialog, setLoadDialog] = useState(false);
    const { setShowModalGeral, showModalGeral } = useShowModal();
    const [estudoOptions, setEstudoOptions] = useState([]);
    const [tema, setTema] = useState(null);
    const [temaOptions, setTemaOptions] = useState([]);
    const [turmaOptions, setTurmaOptions] = useState([]);
    const [turmaConsulta, setTurmaConsulta] = useState('');
    const [sequenciaIncluir, setSequenciaIncluir] = useState(null);
    const [dataAulaIncluir, setDataAulaIncluir] = useState('');
    const [observacaoIncluir, setObservacaoIncluir] = useState('');
    const [idToModal, setIdToModal] = useState();
    const { setShowModalAlunosTurma, showModalAlunosTurma } = useShowModal();
    const [aluno, setAluno] = useState(null);
    const [alunosOptions, setAlunosOptions] = useState([]);
    const [tableAlunos, setTableAlunos] = useState([]);
    const [tipoPresenca, setsetTipoPresenca] = useState(null);
    const [observacaoAluno, setObservacaoAluno] = useState(null);

    const useStyles = makeStyles({
        wrapBtnModal: {
          alignItems: 'flex-start'
        },
        containerModal: {
          boxShadow: '0px 4px 0px 0px rgba(0, 0, 0, 0)',
          borderRadius: '0'
        },
        headColModal: {
          backgroundColor: colors.surface,
          textTransform: 'uppercase',
          borderRadius: '0',
          fontSize: '14px'
        }
    });
    const classes = useStyles();

    const handleEstudoConsulta = (item) => {
      setPlanosAula([]);
      setEstudoConsulta(item);
      getTurmas();
    }

    const handleAnoConsulta = (item) => {
      setAnoConsulta(item.target.value);
      setPlanosAula([]);
      if (anoConsulta.length >= 4) {
        getTurmas();
      }
      
    }

    const handleTurmaConsulta = (item) => {
      setPlanosAula([]);
      setTurmaConsulta(item);
    }

    const handleTema = (item) => {
      setTema(item);
    }

    const handleAlunoIncluir = (item) => {
      setAluno(item)
    }

    const handleTipoPresenca = (item) => {
      setsetTipoPresenca(item)
    }

    useEffect(() => {
      getEstudos();
    }, [])

    async function getEstudos() {
      try {
          const { data } = await api.get(`estudo`);
          setEstudoOptions(data);
      } catch (error) {
          enqueueSnackbar("Erro ao buscar Estudos", ERRORMESSAGE);
      }
    }

    async function getTurmas(showSnack=true) {
      try {
        setTurmaOptions([]);
        setTurmaConsulta([]);

        if ((anoConsulta !== '') && ((estudoConsulta !== null) && (estudoConsulta.value !== undefined) && (estudoConsulta.value !== ''))){

          if (showSnack) {const key = enqueueSnackbar('Pesquisando Turmas ...', INFOMESSAGE);};

          let params = ''
          params += `&ano=${anoConsulta}`
          params += `&idEstudo=${estudoConsulta.value}`

          setLoadDialog(true);
          const { data } = await api.get(`turma?` + params);
          setTurmaOptions(data);
          setLoadDialog(false);          
        }
      } catch (error) {
          enqueueSnackbar("Erro ao buscar Turmas", ERRORMESSAGE);
          setLoadDialog(false);
      }
  }

    const getTemasEstudo = async () => {
      try {
        setLoadDialog(true);
        const { data } = await api.get('/temaestudo?idEstudo='+estudoConsulta.value);
        setTemaOptions(data);
        setLoadDialog(false);
      } catch (err) {
        enqueueSnackbar("Erro ao buscar temas", ERRORMESSAGE);
        setLoadDialog(false);
      }
    }

    const getNextSeq = async () => {
      try {
        setLoadDialog(true);
        const { data } = await api.get('/planoaula/getnextseq/'+turmaConsulta.value);
        setSequenciaIncluir(data.nextSeq);
        setLoadDialog(false);
      } catch (err) {
        enqueueSnackbar("Erro ao buscar próximo sequencial", ERRORMESSAGE);
        setLoadDialog(false);
      }
    }

    async function fetchSearch(showSnack=true) {
        try {
            let params = ''
            if ((anoConsulta === '') || (estudoConsulta === null) || (estudoConsulta.value === undefined) || (estudoConsulta.value === '')
                || (turmaConsulta === null) || (turmaConsulta.value === undefined) || (turmaConsulta.value === '')) {
              enqueueSnackbar("Informe o Estudo, o Ano e a Turma!", ERRORMESSAGE);
            }
            else {
              if (showSnack) {const key = enqueueSnackbar('Pesquisando Planos de Aula ...', INFOMESSAGE);};
              params += `&idTurma=${turmaConsulta.value}`
              setLoadDialog(true);
              const { data } = await api.get(`planoaula?` + params);
              setPlanosAula(data);
              setLoadDialog(false);
            }
        } catch (error) {
            enqueueSnackbar("Erro ao buscar Planos de Aula", ERRORMESSAGE);
            setLoadDialog(false);
        }
    }

    function clearSearch() {
        setPlanosAula([]);
        setAnoConsulta('');
        setEstudoConsulta([]);
        setTurmaConsulta([]);
        setTurmaOptions([]);
    }

    function clearInclude() {
      setTema([]);
      setDataAulaIncluir(null);
      setSequenciaIncluir(null);
      setObservacaoIncluir(null);
    }

    function openModal(action, item) {
      if ((turmaConsulta === null) || (turmaConsulta.value === undefined) || (turmaConsulta.value === '')) {
        enqueueSnackbar("Seleciona a turma!", ERRORMESSAGE);
      }
      else {
        clearInclude();
        getTemasEstudo();
        if (action === "insert") {
            setShowModalGeral({ show: true, title: 'INCLUIR PLANO DE AULA', action: 'inserir' });
            getNextSeq();
        }
        else {
            setShowModalGeral({ show: true, title: 'ALTERAR PLANO DE AULA', action: 'editar', item: item });
        }
      }            
    }

    
    const handleClose = () => {
        setShowModalGeral({ show: false });
      };

    async function sendPlanoAula() {

        const key = enqueueSnackbar('Cadastrando Plano de Aula ...', INFOMESSAGE);
        
        try {
    
          const valoresInseridos = {
            idTurma: turmaConsulta.value,
            idTema: tema.value,
            sequencialAula: sequenciaIncluir,
            dataAula: dataAulaIncluir,
            observacao: observacaoIncluir
          };
    
          setLoadDialog(true);
          const { data } = await api.post("/planoaula/", valoresInseridos);
          if (data.message!=null) throw {message: data.message}
          enqueueSnackbar(<MessageAlert message={"Dados salvos com sucesso!"} />, SUCCESSMESSAGE);
          setShowModalGeral({ show: false });
          fetchSearch(false);
          setLoadDialog(false);
        } catch (err) {
          enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
          setLoadDialog(false);
        }
      };
    
      async function editPlanoAula(id) {
    
        const key = enqueueSnackbar('Alterando Plano de Aula ...', INFOMESSAGE);
    
        try {
    
          const valoresEditados = {
            idTurma: turmaConsulta.value,
            idTema: tema.value,
            sequencialAula: sequenciaIncluir,
            dataAula: dataAulaIncluir,
            observacao: observacaoIncluir
          };
    
          setLoadDialog(true);
          const { data } = await api.put("/planoaula/" + id, valoresEditados);
          if (data.message != null) throw {message: data.message}
          enqueueSnackbar(<MessageAlert message={"Dados salvos com sucesso!"} />, SUCCESSMESSAGE);
          setShowModalGeral({ show: false });
          fetchSearch(false);
          setLoadDialog(false);
    
        } catch (err) {
          enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
          setLoadDialog(false);
        }
      };

      useEffect(() => {
        if (showModalGeral.action === 'editar') {
            setTema({value:`${showModalGeral.item.idTema}`, label:`${showModalGeral.item.codigoTema + " - " + showModalGeral.item.nomeTema }`});
            setSequenciaIncluir(showModalGeral.item.sequencialAula);
            setDataAulaIncluir(showModalGeral.item.dataAula.split("T")[0] + 'T00:00:00');
            setObservacaoIncluir(showModalGeral.item.observacao);
            setIdToModal(showModalGeral.item.id);
        }
      }, [showModalGeral.action])


    async function deletePlanoAula(item) {
        try {
          var resposta = window.confirm("Deseja remover esse registro?");
          if (resposta) {
            const key = enqueueSnackbar('Excluindo Plano de Aula ...', INFOMESSAGE);
            const { data } = await api.delete("/planoaula/" + item.id);
            if ((data.success !== undefined) && (!data.success))
            {
              throw new Error(data.message)
            }
            enqueueSnackbar(<MessageAlert message={"Plano de Aula excluído com sucesso!"} />, SUCCESSMESSAGE);
            fetchSearch(false);
          }
        } catch (err) {
          enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
        }
    };

    const handleCloseAlunosTurma = () => {
      setShowModalAlunosTurma({ show: false });
    };

    function openModalAlunosTurma(item) {
       //clearInclude();
      getPessoasParaPresenca(item.id);
      getAlunosPresenca(item.id);
      setIdToModal(item.id);
      setsetTipoPresenca([]);
      let dataAulaModal = item.dataAula.split("-")[2].split("T")[0] + "/" + item.dataAula.split("-")[1] +"/"+ item.dataAula.split("-")[0]
      setShowModalAlunosTurma({ show: true, title: 'PRESENÇA DA TURMA - ' + dataAulaModal, action: 'editar', item: item });          
    }

    const getPessoasParaPresenca = async (idTurma) => {
      try {
        setLoadDialog(true);
        const { data } = await api.get('/presencaaula/pessoasparapresenca/'+idTurma);
        setAlunosOptions(data);
        setLoadDialog(false);
      } catch (err) {
        enqueueSnackbar("Erro ao buscar dados iniciais", ERRORMESSAGE);
        setLoadDialog(false);
      }
    }

    async function getAlunosPresenca(idPlanoAula) {
      //const key = enqueueSnackbar('Pesquisando Pessoas ...', INFOMESSAGE)
      try {
        const { data } = await api.get("/presencaaula/" + idPlanoAula);
        if (data) {
          setTableAlunos(data);
        }
      } catch (error) {
        enqueueSnackbar("Erro ao buscar alunos", ERRORMESSAGE);
      }
    }

    async function savePresencaAula() {

      const key = enqueueSnackbar('Cadastrando Aluno ...', INFOMESSAGE);
      
      try {        
        
        const valoresInseridos = {
          idPlanoAula: idToModal,
          idPessoa: aluno.value,
          tipo: tipoPresenca.value,
          observacao: observacaoAluno
        };
          
        setLoadDialog(true);
        const { data } = await api.post("/presencaaula/", valoresInseridos);
        if (data.message!=null) throw {message: data.message}
        enqueueSnackbar(<MessageAlert message={"Dados salvos com sucesso!"} />, SUCCESSMESSAGE);
        setAluno([]);
        setObservacaoAluno("");
        getPessoasParaPresenca(idToModal);
        getAlunosPresenca(idToModal);
        setLoadDialog(false);
      } catch (err) {
        enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
        setLoadDialog(false);
      }
    };

    async function deletePresencaAula(idPlanoAula,idPessoa) {
      try {
        var resposta = window.confirm("Deseja remover esse registro?");
        if (resposta) {
          const key = enqueueSnackbar('Excluindo Turma ...', INFOMESSAGE);
          const { data } = await api.delete("/presencaaula/" + idPlanoAula + "/" + idPessoa);
          if ((data.success !== undefined) && (!data.success))
          {
            throw new Error(data.message)
          }
          enqueueSnackbar(<MessageAlert message={"Aluno excluído da turma com sucesso!"} />, SUCCESSMESSAGE);
          fetchSearch(false);
          getPessoasParaPresenca(idPlanoAula);
          getAlunosPresenca(idPlanoAula);
          setLoadDialog(false);
        }
      } catch (err) {
        enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
      }
    };
    

    const bodyPlanoAula = (
        <StyledBox className={'disableFocus alert-modal'}>
          <TitleStyle2>
            {showModalGeral.title}
            <CancelIcon
              style={modalClose}
              color={colors.error}
              size="20"
              title="Fechar"
              onClick={() => handleClose()}
            />
          </TitleStyle2>
          <ModalBox>
              <Wrap>
                <WrapInput style={{ position: 'relative', width: '18%'}}>
                  <LabelInput>Sequência <span style={{ color: '#FF0000' }}>*</span></LabelInput>
                  <Input
                  placeholder="Inserir sequência"
                  style={inputStyle}
                  maxLength="150"
                  type="text"
                  name="sequencia"
                  onChange={e => setSequenciaIncluir(e.target.value)}
                  value={sequenciaIncluir}
                  />
                </WrapInput>                
                <WrapInput style={{ position: 'relative', width: '70%'}}>
                  <LabelInput>Tema <span style={{ color: '#FF0000' }}>*</span></LabelInput>
                  <MySelect
                    styles={multSelect}
                    placeholder={'Selecione o tema...'}
                    value={tema}
                    isClearable={true}
                    isSearchable={true}
                    onChange={handleTema}
                    options={temaOptions.map((item) => { return { value: item.id, label: item.codigo + " - " + item.nome}; })}
                  />
                </WrapInput>
              </Wrap>
              <Wrap>
                <WrapInput style={{ position: 'relative', width: '18%'}}>
                  <LabelInput style={{padding: '0.7px'}}>Data da Aula <span style={{ color: '#FF0000' }}>*</span></LabelInput>
                  <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                    <KeyboardDatePicker 
                      value={dataAulaIncluir} 
                      onChange={setDataAulaIncluir}
                      format="dd/MM/yyyy"
                      clearLabel="Limpar"
                      cancelLabel="Cancelar"
                      clearable
                      minDateMessage="Não é possível buscar por datas anteiores a 01/01/1900."
                      invalidDateMessage="A data inserida não é válida!"
                      helperText={""}
                      id="dataAula"
                      //maxDate={new Date(new Date())}
                      margin="dense"
                      inputVariant="outlined"
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </WrapInput>
                <WrapInput style={{ position: 'relative', width: '70%'}}>
                <LabelInput>Observação</LabelInput>
                <Input
                  placeholder="Inserir observações"
                  style={inputStyle}
                  maxLength="150"
                  type="text"
                  name="observacao"
                  onChange={e => setObservacaoIncluir(e.target.value)}
                  value={observacaoIncluir}
                />
              </WrapInput>
            </Wrap>
            <Wrap>
              <WrapInput className="wrapInputModal">
                <Items>
                  <CancelarBtn type="button" onClick={handleClose}> Cancelar </CancelarBtn>
                  <Button type="button" onClick={() => showModalGeral.action === 'inserir' ? sendPlanoAula() : editPlanoAula(idToModal)} > Salvar </Button>
                </Items>
              </WrapInput>
            </Wrap>
          </ModalBox>
        </StyledBox>
      );

      const bodyAlunosTurma = (
        <StyledBox className={'disableFocus alert-modal'}>
          <TitleStyle2>
            {showModalAlunosTurma.title}
            <CancelIcon
              style={modalClose}
              color={colors.error}
              size="20"
              title="Fechar"
              onClick={() => handleCloseAlunosTurma()}
            />
          </TitleStyle2>
          <ModalBox>
            <Wrap style={{ position: 'relative', padding: '1px', width:'97%' }}>
              <WrapInput style={{ position: 'relative', width: '50%', marginLeft: '0px'}}>
                  <LabelInput>Pessoas <span style={{ color: '#FF0000' }}>*</span></LabelInput>
                  <MySelect
                    styles={multSelect}
                    placeholder={'Opções...'}
                    value={aluno}
                    isClearable={true}
                    isSearchable={true}
                    onChange={handleAlunoIncluir}
                    options={alunosOptions.map((item) => { return { value: item.id, label: item.nome}; })}
                  />
              </WrapInput>
              <WrapInput style={{ position: 'relative', width: '20%', marginLeft: '0px'}}>
                  <LabelInput>Tipo <span style={{ color: '#FF0000' }}>*</span></LabelInput>
                  <MySelect
                    styles={multSelect}
                    placeholder={'Opções...'}
                    value={tipoPresenca}
                    isClearable={true}
                    isSearchable={true}
                    onChange={handleTipoPresenca}
                    options={[{ value: 'Presencial', label: 'Presencial' }, { value: 'On-Line', label: 'On-Line' }, { value: 'Ausente', label: 'Ausente' }]}
                  />
              </WrapInput>
            </Wrap>
            <Wrap style={{ position: 'relative', padding: '1px', width:'99%'  }}>
              <WrapInput style={{ position: 'relative', width: '80%'}}>
                  <LabelInput>Observações</LabelInput>
                  <Input
                    placeholder="Inserir Observações"
                    style={inputStyle}
                    maxLength="500"
                    type="text"
                    name="observacaoAluno"
                    onChange={e => setObservacaoAluno(e.target.value)}
                    value={observacaoAluno}
                  />
              </WrapInput>
            </Wrap>
            <Wrap>
              <WrapInput className="wrapInputModal" >
                <Items style={{height: '16px'}}>
                  <CancelarBtn className='widthButton' type="button" onClick={handleCloseAlunosTurma}> Sair </CancelarBtn>
                  <Button style={{ backgroundColor: '#3FC248' }} className='widthButton' type="button" onClick={() => savePresencaAula()}>&nbsp;Adicionar<AddIcon></AddIcon></Button>
                </Items>
              </WrapInput>
            </Wrap>
            <WrapList>
              {
                <BodyList style={{ width: '93%', height: '35vh'}}>
                <TableContainer className={classes.containerModal}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.headColModal} align="center">Aluno</TableCell>
                        <TableCell className={classes.headColModal} align="center">Tipo</TableCell>
                        <TableCell className={classes.headColModal} align="center">Obs</TableCell>
                        <TableCell className={classes.headColModal} align="center">Ações</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody style={{ width: '90%', backgroundColor: 'white' }}>
                      {
                        tableAlunos && tableAlunos.map((row, index) => {
                          return (
                            <TableRow style={{ backgroundColor: index % 2 === 0 ? shade(0.1, colors.surface) : '' }} key={index}>
                              <TableCell align="center">
                                {row.nomeAluno}
                              </TableCell>
                              <TableCell align="center">
                                {row.tipo}
                              </TableCell>
                              <TableCell align="center">
                                {row.observacao}
                              </TableCell>
                              <TableCell align="center">
                                <AiTwotoneDelete
                                  style={{ cursor: "pointer", marginLeft: "5px" }}
                                  color={tint(0.1, colors.error)}
                                  size="20"
                                  title="Remover"
                                  onClick={() => deletePresencaAula(row.idPlanoAula,row.idPessoa)}
                                />
                              </TableCell>
                            </TableRow>
                          )
                        })
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
                </BodyList>
                }
            </WrapList>
          </ModalBox>
        </StyledBox>
      );
    

    const modalLoad = (
        <div className={'disabeFocus alert-modal'}>
            <WrapInput className="alertText" style={{ alignItems: 'center' }}>
            <CircularProgress style={{ color: '#043D5D' }} /> <h3>Carregando...</h3>
            </WrapInput>
        </div>
    );

    return (
        <Container>
          <Header/>
            <Modal open={showModalGeral.show}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
            {bodyPlanoAula}
            </Modal>
            <Modal open={showModalAlunosTurma.show}
                onClose={handleCloseAlunosTurma}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
            {bodyAlunosTurma}
            </Modal>
            <SearchBox style={{ margin: '20px 0 20px 0' }}>
                <TitleStyle2>Pesquisa de Planos de Aula</TitleStyle2>
                <Wrap>
                <WrapInput style={{ width: '25%', paddingRight: '1px' }}> <LabelInput> Estudo <span style={{ color: '#FF0000' }}>*</span></LabelInput>
                  <MySelect
                    styles={multSelect}
                    placeholder={'Selecione o estudo...'}
                    value={estudoConsulta}
                    isClearable={true}
                    isSearchable={true}
                    onChange={handleEstudoConsulta}
                    onBlur={getTurmas}
                    options={estudoOptions.map((item) => { return { value: item.id, label: item.nome }; })}
                  />
                </WrapInput>
                <WrapInput style={{ width: '8%', paddingRight: '5px' }}> <LabelInput> Ano <span style={{ color: '#FF0000' }}>*</span> </LabelInput>
                <InputMask
                    mask="9999"
                    maskChar=""
                    placeholder="Informe o Ano"
                    style={inputStyle}
                    type="text"
                    name="anoConsulta"
                    onChange={handleAnoConsulta}
                    onBlur={getTurmas}
                    value={anoConsulta}
                  />
                </WrapInput>
                <WrapInput style={{ width: '20%', paddingRight: '1px' }}> <LabelInput> Turma <span style={{ color: '#FF0000' }}>*</span></LabelInput>
                  <MySelect
                    styles={multSelect}
                    placeholder={'Selecione a turma...'}
                    value={turmaConsulta}
                    isClearable={true}
                    isSearchable={true}
                    onChange={handleTurmaConsulta}
                    options={turmaOptions.map((item) => { return { value: item.id, label: item.codigo }; })}
                  />
                </WrapInput>
                <WrapInput style={{ width:'40%', marginTop: '10px' }}>
                    <ItemsSearch>
                    <IconeSearch size={25} title="Pesquisar" onClick={() => fetchSearch(true)} />
                    <Button style={{ backgroundColor: '#3FC248' }} className='widthButton' onClick={() => openModal('insert')}>&nbsp;Novo<AddIcon></AddIcon></Button>
                    <Button className='widthButton' onClick={() => clearSearch()}>&nbsp;Limpar<DeleteOutlineIcon></DeleteOutlineIcon></Button>
                    </ItemsSearch>
                </WrapInput>
                </Wrap>
            </SearchBox>
            <WrapList>
                <HeaderList>
                <span style={{ marginLeft: '20px' }}> PLANOS DE AULA</span>
                </HeaderList>
                {
                <BodyList>
                    <TableContainer>
                        <Table style={{ backgroundColor: colors.surface, width: '100%' }} className={classes.table} size="small">
                            <TableHead>
                            <TableRow>
                                <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">SEQUÊNCIA</TableCell>
                                <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">TEMA</TableCell>
                                <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">DATA</TableCell>
                                <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">OBSERVAÇÃO</TableCell>
                                <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">AÇÕES</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody style={{ width: '100%' }}>
                            {
                                planosAula && planosAula.map((row, index) => {
                                return (
                                    <TableRow style={{ backgroundColor: index % 2 === 0 ? shade(0.1, colors.surface) : '' }} key={index}>
                                        <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">{row.sequencialAula}</TableCell>
                                        <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">{row.codigoTema + " - " + row.nomeTema}</TableCell>
                                        <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">{row.dataAula.split("-")[2].split("T")[0] + "/" + row.dataAula.split("-")[1] +"/"+ row.dataAula.split("-")[0]}</TableCell>
                                        <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">{row.observacao}</TableCell>
                                        <TableCell
                                            style={{ color: textTableColor, border: 'none', display: 'flex', justifyContent: 'center' }}
                                            align="center">
                                            <AiOutlineEdit
                                            onClick={() => openModal('update',row)}
                                            style={{ cursor: "pointer", marginLeft: '5px'  }}
                                            color={colors.primary}
                                            size="22"
                                            title="Editar"
                                            />          
                                            <AiOutlineDelete
                                            onClick={() => deletePlanoAula(row)}
                                            style={{ cursor: "pointer", marginLeft: '5px'  }}
                                            color={colors.primary}
                                            size="22"
                                            title="Excluir"
                                            />
                                            <AiOutlineTeam
                                            onClick={() => openModalAlunosTurma(row)}
                                            style={{ cursor: "pointer", marginLeft: '5px'  }}
                                            color={colors.primary}
                                            size="22"
                                            title="Presença"
                                            />                            
                                        </TableCell>
                                    </TableRow>
                                    )
                                    })
                                }
                                </TableBody>
                        </Table>
                    </TableContainer >
 
                </BodyList>  
                }
            </WrapList>
            <Modal open={loadDialog}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {modalLoad}
            </Modal>
        </Container>
    )
}