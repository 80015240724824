import React, { useState, useContext, useEffect } from 'react';
import { Wrap, WrapInput, WrapList } from '../../components/Wrap';
import { LabelInput, inputStyle,multSelect,Input } from '../../components/Input';
import { SearchBox, IconeSearch, ItemsSearch } from "../../components/Search";
import { Button,CancelarBtn } from "../../components/Button";
import { Container, TitleStyle2, Items} from './styles'
import { HeaderList, BodyList } from '../../components/TabelaCadastro';
import { Modal, CircularProgress } from "@material-ui/core";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer } from '../../components/SharedComponents/table';
import { shade } from 'polished'
import { AiOutlineEdit } from "react-icons/ai";
import { ThemeContext } from 'styled-components';
import { ERRORMESSAGE, INFOMESSAGE,SUCCESSMESSAGE } from '../../utils/types-messages';
import { useSnackbar } from 'notistack';
import { api } from '../../services/axios';
import { makeStyles } from '@material-ui/core/styles';
import { MySelect } from "../../components/InputSelect";
import InputMask from 'react-input-mask';
import Header from "../../components/Header";
import { useShowModal } from '../../contexts/SharedContext';
import { modalClose, ModalBox, StyledBox } from '../../components/Modal';
import CancelIcon from '@material-ui/icons/Cancel';
import { MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import { ptBR } from 'date-fns/locale';
import DateFnsUtils from '@date-io/date-fns';
import MessageAlert from '../../components/MessageAlert';


export default function ManutencaoAlunoTurma(props){
    const { enqueueSnackbar } = useSnackbar();
    const { colors, textTableColor } = useContext(ThemeContext);
    const [anoConsulta, setAnoConsulta] = useState('');
    const [estudoConsulta, setEstudoConsulta] = useState('');
    const [loadDialog, setLoadDialog] = useState(false);
    const [estudoOptions, setEstudoOptions] = useState([]);
    const [turmaOptions, setTurmaOptions] = useState([]);
    const [turmaConsulta, setTurmaConsulta] = useState('');
    const [tableAlunos, setTableAlunos] = useState([]);
    const { setShowModalGeral, showModalGeral } = useShowModal();
    const [observacaoAluno, setObservacaoAluno] = useState([]);
    const [situacaoAluno, setSituacaoAluno] = useState([]);
    const [presencaAluno, setPresencaAluno] = useState([]);
    const [avaliacaoAluno, setAvaliacaoAluno] = useState([]);
    const [dataRetiroAluno, setDataRetiroAluno] = useState([]);
    const [dataBatismoAluno, setDataBatismoAluno] = useState([]);
    const [idPessoa, setIdPessoa] = useState([]);
    
    const useStyles = makeStyles({
        wrapBtnModal: {
          alignItems: 'flex-start'
        },
        containerModal: {
          boxShadow: '0px 4px 0px 0px rgba(0, 0, 0, 0)',
          borderRadius: '0'
        },
        headColModal: {
          backgroundColor: colors.surface,
          textTransform: 'uppercase',
          borderRadius: '0',
          fontSize: '14px'
        }
    });
    const classes = useStyles();

    const handleEstudoConsulta = (item) => {
      setEstudoConsulta(item);
      getTurmas();
      setTableAlunos([]);
    }

    const handleAnoConsulta = (item) => {
      setAnoConsulta(item.target.value);
      if (anoConsulta.length >= 4) {
        getTurmas();
        setTableAlunos([]);
      }
      
    }

    const handleTurmaConsulta = (item) => {
      setTurmaConsulta(item);
      setTableAlunos([]);
    }

    useEffect(() => {
      getEstudos();
    },[]);

    async function getEstudos() {
      try {
          const { data } = await api.get(`estudo`);
          setEstudoOptions(data);
      } catch (error) {
          enqueueSnackbar("Erro ao buscar Estudos", ERRORMESSAGE);
      }
    }

    async function getTurmas(showSnack=true) {
      try {
        setTurmaOptions([]);
        setTurmaConsulta([]);
        setTableAlunos([]);

        if ((anoConsulta !== '') && ((estudoConsulta !== null) && (estudoConsulta.value !== undefined) && (estudoConsulta.value !== ''))){

          if (showSnack) {const key = enqueueSnackbar('Pesquisando Turmas ...', INFOMESSAGE);};

          let params = ''
          params += `&ano=${anoConsulta}`
          params += `&idEstudo=${estudoConsulta.value}`

          setLoadDialog(true);
          const { data } = await api.get(`turma?` + params);
          setTurmaOptions(data);
          setLoadDialog(false);          
        }
      } catch (error) {
          enqueueSnackbar("Erro ao buscar Turmas", ERRORMESSAGE);
          setLoadDialog(false);
      }
  }

  async function fetchSearch(showSnack=true) {
      try {
          if ((anoConsulta === '') || (estudoConsulta === null) || (estudoConsulta.value === undefined) || (estudoConsulta.value === '')
              || (turmaConsulta === null) || (turmaConsulta.value === undefined) || (turmaConsulta.value === '')) {
            enqueueSnackbar("Informe o Estudo, o Ano e a Turma!", ERRORMESSAGE);
          }
          else {
            if (showSnack) {const key = enqueueSnackbar('Pesquisando Alunos da Turma ...', INFOMESSAGE);};
            setLoadDialog(true);
            const { data } = await api.get(`pessoaturma/` + turmaConsulta.value);
            setTableAlunos(data);
            setLoadDialog(false);
          }
      } catch (error) {
          enqueueSnackbar("Erro ao buscar Alunos da Turma", ERRORMESSAGE);
          setLoadDialog(false);
      }
  }

  function clearSearch() {
      setAnoConsulta('');
      setEstudoConsulta([]);
      setTurmaConsulta([]);
      setTurmaOptions([]);
      setTableAlunos([]);
  }

  const handleClose = () => {
    setShowModalGeral({ show: false });
  };

  useEffect(() => {
    if (showModalGeral.action === 'editar') {
        setObservacaoAluno(showModalGeral.item.observacao);
        setSituacaoAluno({value:`${showModalGeral.item.situacao}`, label:`${showModalGeral.item.situacao}`});
        setPresencaAluno(showModalGeral.item.presenca);
        setAvaliacaoAluno(showModalGeral.item.avaliacao);
        setDataRetiroAluno(showModalGeral.item.dataRetiro + 'T00:00:00');
        setDataBatismoAluno(showModalGeral.item.dataBatismo + 'T00:00:00');
        setIdPessoa(showModalGeral.item.idPessoa);
    }
  }, [showModalGeral.action]);

  
  function openModal(item) {
    if ((turmaConsulta === null) || (turmaConsulta.value === undefined) || (turmaConsulta.value === '')) {
      enqueueSnackbar("Seleciona a turma!", ERRORMESSAGE);
    }
    else {
      clearInclude();
      setShowModalGeral({ show: true, title: 'DADOS DO ALUNO(A): ' + item.nomeAluno, action: 'editar', item:item });      
    }            
  }

  function clearInclude() {
    setObservacaoAluno(null);
    setSituacaoAluno([]);
    setPresencaAluno(null);
    setAvaliacaoAluno(null);
    setDataRetiroAluno(null);
    setDataBatismoAluno(null);
  }

  const situacaoOptions = [
    { value: "Cursando", label: "Cursando" },
    { value: "Aprovado", label: "Aprovado" },
    { value: "Reprovado", label: "Reprovado" },
    { value: "Desistiu", label: "Desistiu" },
  ]

  const handleSituacaoAluno = (item) => {
    setSituacaoAluno(item);
  }

  async function sendAluno() {

    const key = enqueueSnackbar('Salvando Dados do Aluno ...', INFOMESSAGE);
    
    try {

      const valores = {
        presenca: presencaAluno,
        avaliacao: avaliacaoAluno,
        situacao: situacaoAluno.value,
        dataRetiro: dataRetiroAluno,
        dataBatismo: dataBatismoAluno,
        observacao: observacaoAluno
      };

      setLoadDialog(true);
      const { data } = await api.put("/pessoaturma/" + turmaConsulta.value + "/" + idPessoa, valores);
      if (data.message!=null) throw {message: data.message}
      enqueueSnackbar(<MessageAlert message={"Dados salvos com sucesso!"} />, SUCCESSMESSAGE);
      setShowModalGeral({ show: false });
      clearInclude();
      fetchSearch(false);
      setLoadDialog(false);
    } catch (err) {
      enqueueSnackbar(<MessageAlert message={err.message} />, ERRORMESSAGE);
      setLoadDialog(false);
    }
  };

  const bodyAluno = (
    <StyledBox className={'disableFocus alert-modal'}>
      <TitleStyle2>
        {showModalGeral.title}
        <CancelIcon
          style={modalClose}
          color={colors.error}
          size="20"
          title="Fechar"
          onClick={() => handleClose()}
        />
      </TitleStyle2>
      <ModalBox>
        <Wrap>
          <WrapInput style={{ position: 'relative', width: '60%'}}>
            <LabelInput>Situação <span style={{ color: '#FF0000' }}>*</span></LabelInput>
            <MySelect
              styles={multSelect}
              placeholder={'Selecione a situação...'}
              value={situacaoAluno}
              isClearable={true}
              isSearchable={true}
              onChange={handleSituacaoAluno}
              options={situacaoOptions}
            />
          </WrapInput>
        </Wrap>
        <Wrap>
          <WrapInput style={{ position: 'relative', width: '10%'}}>
            <LabelInput>Presença(%)</LabelInput>
            <InputMask
            mask="99999"
            maskChar=""
            //placeholder="% de presença"
            style={inputStyle}
            maxLength="3"
            type="text"
            name="presencaAluno"
            onChange={e => setPresencaAluno(e.target.value)}
            value={presencaAluno}
            />
          </WrapInput>
          <WrapInput style={{ position: 'relative', width: '10%'}}>
            <LabelInput>Avaliação(%)</LabelInput>
            <InputMask
            mask="99999"
            maskChar=""
            //placeholder="% de avaliação"
            style={inputStyle}
            maxLength="3"
            type="text"
            name="avaliacaoAluno"
            onChange={e => setAvaliacaoAluno(e.target.value)}
            value={avaliacaoAluno}
            />
          </WrapInput>
          <WrapInput style={{ position: 'relative', width: '10%'}}>
            <LabelInput style={{padding: '0.7px'}}>Data do Retiro</LabelInput>
            <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                <KeyboardDatePicker 
                  value={dataRetiroAluno} 
                  onChange={setDataRetiroAluno}
                  format="dd/MM/yyyy"
                  clearLabel="Limpar"
                  cancelLabel="Cancelar"
                  clearable
                  minDateMessage="Não é possível buscar por datas anteiores a 01/01/1900."
                  invalidDateMessage="A data inserida não é válida!"
                  helperText={""}
                  id="dataNasc"
                  maxDate={new Date(new Date())}
                  margin="dense"
                  inputVariant="outlined"
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </WrapInput>
            <WrapInput style={{ position: 'relative', width: '10%'}}>
              <LabelInput style={{padding: '0.7px'}}>Data do Batismo</LabelInput>
              <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                  <KeyboardDatePicker 
                    value={dataBatismoAluno} 
                    onChange={setDataBatismoAluno}
                    format="dd/MM/yyyy"
                    clearLabel="Limpar"
                    cancelLabel="Cancelar"
                    clearable
                    minDateMessage="Não é possível buscar por datas anteiores a 01/01/1900."
                    invalidDateMessage="A data inserida não é válida!"
                    helperText={""}
                    id="dataNasc"
                    maxDate={new Date(new Date())}
                    margin="dense"
                    inputVariant="outlined"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
            </WrapInput>
        </Wrap>
        <Wrap>
          <WrapInput style={{ position: 'relative', width: '70%'}}>
            <LabelInput>Observação</LabelInput>
            <Input
            placeholder="Inserir observação"
            style={inputStyle}
            maxLength="500"
            type="text"
            name="observacaoAluno"
            onChange={e => setObservacaoAluno(e.target.value)}
            value={observacaoAluno}
            />
          </WrapInput>
      </Wrap>
      <Wrap>
        <WrapInput className="wrapInputModal">
          <Items>
            <CancelarBtn type="button" onClick={handleClose}> Cancelar </CancelarBtn>
            <Button type="button" onClick={() => sendAluno()} > Salvar </Button>
          </Items>
        </WrapInput>
      </Wrap>
      </ModalBox>
    </StyledBox>
  );

   
  const modalLoad = (
      <div className={'disabeFocus alert-modal'}>
          <WrapInput className="alertText" style={{ alignItems: 'center' }}>
          <CircularProgress style={{ color: '#043D5D' }} /> <h3>Carregando...</h3>
          </WrapInput>
      </div>
  );

  return (
      <Container>
        <Header/>
        <Modal open={showModalGeral.show}
              onClose={handleClose}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
          >
          {bodyAluno}
          </Modal>
          <SearchBox style={{ margin: '20px 0 20px 0' }}>
              <TitleStyle2>Pesquisa de Alunos da Turma</TitleStyle2>
              <Wrap>
              <WrapInput style={{ width: '25%', paddingRight: '1px' }}> <LabelInput> Estudo <span style={{ color: '#FF0000' }}>*</span></LabelInput>
                <MySelect
                  styles={multSelect}
                  placeholder={'Selecione o estudo...'}
                  value={estudoConsulta}
                  isClearable={true}
                  isSearchable={true}
                  onChange={handleEstudoConsulta}
                  onBlur={getTurmas}
                  options={estudoOptions.map((item) => { return { value: item.id, label: item.nome }; })}
                />
              </WrapInput>
              <WrapInput style={{ width: '8%', paddingRight: '5px' }}> <LabelInput> Ano <span style={{ color: '#FF0000' }}>*</span> </LabelInput>
              <InputMask
                  mask="9999"
                  maskChar=""
                  placeholder="Informe o Ano"
                  style={inputStyle}
                  type="text"
                  name="anoConsulta"
                  onChange={handleAnoConsulta}
                  onBlur={getTurmas}
                  value={anoConsulta}
                />
              </WrapInput>
              <WrapInput style={{ width: '20%', paddingRight: '1px' }}> <LabelInput> Turma <span style={{ color: '#FF0000' }}>*</span></LabelInput>
                <MySelect
                  styles={multSelect}
                  placeholder={'Selecione a turma...'}
                  value={turmaConsulta}
                  isClearable={true}
                  isSearchable={true}
                  onChange={handleTurmaConsulta}
                  options={turmaOptions.map((item) => { return { value: item.id, label: item.codigo }; })}
                />
              </WrapInput>
              <WrapInput style={{ width:'40%', marginTop: '10px' }}>
                  <ItemsSearch>
                  <IconeSearch size={25} title="Pesquisar" onClick={() => fetchSearch(true)} />
                  <Button className='widthButton' onClick={() => clearSearch()}>&nbsp;Limpar<DeleteOutlineIcon></DeleteOutlineIcon></Button>
                  </ItemsSearch>
              </WrapInput>
              </Wrap>
          </SearchBox>
          <WrapList>
              <HeaderList>
              <span style={{ marginLeft: '20px' }}> ALUNOS DA TURMA</span>
              </HeaderList>
              {
              <BodyList>
                  <TableContainer>
                      <Table style={{ backgroundColor: colors.surface, width: '100%' }} className={classes.table} size="small">
                          <TableHead>
                          <TableRow>
                              <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">ALUNO</TableCell>
                              <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">TIPO</TableCell>
                              <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">PRESENÇA</TableCell>
                              <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">AVALIAÇÔES</TableCell>
                              <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">SITUAÇÃO</TableCell>
                              <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">RETIRO</TableCell>
                              <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">BATISMO</TableCell>
                              <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">OBSERVAÇÕES</TableCell>
                              <TableCell className={classes.head} style={{ color: textTableColor, fontWeight: 'bold', border: 'none', fontSize: '12px' }} align="center">AÇÕES</TableCell>
                          </TableRow>
                          </TableHead>
                          <TableBody style={{ width: '100%' }}>
                          {
                              tableAlunos && tableAlunos.map((row, index) => {
                              return (
                                  <TableRow style={{ backgroundColor: index % 2 === 0 ? shade(0.1, colors.surface) : '' }} key={index}>
                                      <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">{row.nomeAluno}</TableCell>
                                      <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">{row.tipo}</TableCell>
                                      <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">{(row.presenca != null)?row.presenca + "%":""}</TableCell>
                                      <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">{(row.avaliacao != null)?row.avaliacao + "%":""}</TableCell>
                                      <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">{row.situacao}</TableCell>
                                      <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">{(row.dataRetiro != null)?row.dataRetiro.split("-")[2].split("T")[0] + "/" + row.dataRetiro.split("-")[1] +"/"+ row.dataRetiro.split("-")[0]:""}</TableCell>
                                      <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">{(row.dataBatismo != null)?row.dataBatismo.split("-")[2].split("T")[0] + "/" + row.dataBatismo.split("-")[1] +"/"+ row.dataBatismo.split("-")[0]:""}</TableCell>
                                      <TableCell style={{ color: textTableColor, border: 'none', fontSize: '12px' }} component="th" scope="row" align="center">{(row.observacao != null)?row.observacao.substring(0,40):""}</TableCell>
                                      <TableCell
                                          style={{ color: textTableColor, border: 'none', display: 'flex', justifyContent: 'center' }}
                                          align="center">
                                          <AiOutlineEdit
                                          onClick={() => openModal(row)}
                                          style={{ cursor: "pointer", marginLeft: '5px'  }}
                                          color={colors.primary}
                                          size="22"
                                          title="Editar"
                                          />             
                                      </TableCell>
                                  </TableRow>
                                  )
                                  })
                              }
                              </TableBody>
                      </Table>
                  </TableContainer >

              </BodyList>  
              }
          </WrapList>
          <Modal open={loadDialog}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
          >
              {modalLoad}
          </Modal>
      </Container>
  )
}